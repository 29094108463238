.section1_image {
  max-width: 75%;
}

.section2 {
  min-height: 100vh;
}

.section2_bg_video {
  position: absolute;
  z-index: -2;
  object-fit: cover;
  width: 100%;
}

.section2_bg_overlay {
  background-color: #0B2B44;
  opacity: 0.50;
  position: absolute;
  z-index: -1;
  width: 100%;
}

.section4_slider {
  box-shadow: 8px 8px 38px #eeeefc;
  padding: 50px 40px;
  max-width: 750px;
  min-height: 400px;
  background-color: #FFFFFF;
}

.section4_slide_col {
  padding: 15px;
}

.section4_slide_image_col {
  overflow: hidden;
}

.section4_slide_image {
  min-height: 400px;
  max-height: 400px;
}

.section4_slide_title {
  font-size: 22px;
}

.section4_slide_description {
  font-size: 16px;
  font-weight: 300;
}

.section4_slider_next {
  color: #00DAB2;
  font-size: 30px;
}

.section4_slider_prev {
  color: #00DAB2;
  font-size: 30px;
}

.text_color_white {
  color: #FFFFFF;
}

.partnerLogo {
  width: 400px;
}

.partnerLogo2 {
  width: 300px;
  height: auto;
}
