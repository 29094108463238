.welcomeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.welcomeText {
    font-weight: 400;
}

.userName {
    font-weight: 500;
}

.innerTextContainer {
    margin: 7.5px;
}

.section1Text {
    margin-bottom: 0px;
}

.profilePicture {
    border-radius: 100px;
    margin: 7.5px;
    width: 100px;
}

.sectionLink {
    display: flex;
    align-items: center;
}

.sectionLink>* {
    margin: 7.5px;
}

.row_col {
    flex-wrap: nowrap;
}

.section2_col {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
    min-width: 350px;
}

.section2_col_img {
    max-width: 75%;
}

.section2_col_row {
    flex-wrap: nowrap;
}

.section2_col_data_container {
    margin-left: 5px;
    margin-right: 5px;
}

.section2_col_data_container>* {
    margin: 5px; 
}

.section2_col_icon {
    color: #aaaaaa;
}

.section2_col_data {
    color: #aaaaaa;
    font-weight: 300;
    font-size: 14px;
}

.section2_col_title {
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.section2_col_p {
    font-weight: 300;
}

.section2_col_btn {
    margin-top: 23px;
}

.section3_li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2.5px;
}

.section3_li>* {
    color: #545454;
    margin: 5px;
}

.spinner {
    font-size: 20px;
    animation: spinner 1s infinite;
}

.container {
    padding-top: 35px;
}

.leftCol {
    padding-top: 35px;
    border-top: #dee2e6 1px solid;
    border-right: #dee2e6 1px solid;
}

.rightCol {
    padding-top: 35px;
    border-top: #dee2e6 1px solid;
}

.tabsContainer {
    padding-bottom: 35px;
}

.logoutContainer {
    padding-top: 35px;
    border-top: #dee2e6 1px solid;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}