.image {
    width: 400px;
}

.col {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
    max-width: 350px;
}

.lessons_col {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
}

.row_col {
    flex-wrap: nowrap;
}

.section2_col {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
    min-width: 350px;
}

.section2_col_img {
    max-width: 75%;
}

.section2_col_row {
    flex-wrap: nowrap;
}

.section2_col_data_container {
    margin-left: 5px;
    margin-right: 5px;
}

.section2_col_data_container>* {
    margin: 5px; 
}

.section2_col_icon {
    color: #aaaaaa;
    /* color: #00DAB2; */
}

.section2_col_data {
    color: #aaaaaa;
    /* color: #00DAB2; */
    font-weight: 300;
    font-size: 14px;
}

.section2_col_title {
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.section2_col_p {
    font-weight: 300;
}

.section2_col_btn {
    margin-top: 23px;
}


.col_row {
    flex-wrap: nowrap;
}

.col_data_container {
    margin-left: 5px;
    margin-right: 5px;
}

.col_data_container>* {
    margin: 5px;

    color: #aaaaaa;
}

.col_image {
    max-width: 75%;
}

.col_title {
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.col_p {
    font-weight: 300;
    font-size: 16px;
}

.col_btn {
    margin-top: 23px;
}

.spinner {
    font-size: 50px;
    animation: spinner 1s infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
