.col {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
    max-width: 350px;
}

.col_title {
    font-size: 19px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.col_p {
    font-weight: 300;
    font-size: 16px;
}

.col_link {
    font-weight: 300;
    font-size: 16px;
}

.image {
    max-width: 75%;
}

.sign_up_link {
    font-weight: 600;
}
