.login {
    height: 100vh;
}

.logo {
    width: 300px;
    margin-bottom: 32.5px;
}

.spinner {
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
}

.textSize {
    font-size: 14px;
}

.resetPassword {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0px;
}

.resetPassword:hover {
    color: #00DAB2;
}

.googleIcon {
    margin: 7.5px;
    padding: 7.5px;
    border: 2px solid #545454;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
}

.googleIcon:hover, .facebookIcon:hover {
    background-color: #5454543F;
}

.facebookIcon {
    margin: 7.5px;
    padding: 7.5px;
    border: 2px solid #545454;
    color: #4f6aa3;
    font-size: 24px;
    border-radius: 5px;
    cursor: pointer;
}

.providerLogin {
    margin-top: 7.5px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}