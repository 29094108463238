.infoCard {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
    max-width: 350px;
}

@media only screen and (max-width: 768px) {
    .infoCard {
        max-width: none;
    }
}

.dot {
    padding-right: 10px;
    width: 6px;
}

.lockedDiv {
    background-color: #e5e5e5;
    height: 200px;
    border-radius: 5px;
}

.lockedIcon {
    font-size: 80px;;
    color: #909090;
    margin-bottom: 15px;
}

.lockedText {
    font-size: 24px;
    color: #909090;
}

.ul {
    margin-bottom: 0px;
}

.li {
    padding: 15px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
}

.li_icon {
    padding-right: 7.5px;
}

.lia {
    text-decoration: none;
    width: 100%;
}

.image {
    max-width: 33%;
}

.courseCard {
    padding: 25px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 6px 6px 38px #eeeefc;
    border-width: 1px;
    border-color: #ffffff;
    margin: 15px;
}

.course_img {
    max-width: 60%;
}

.buttonDiv {
    flex: 1;
}

.buttonDiv > * {
    width: 100%;
}

.dataStack {
    height: 100%;
}

.instructor > * {
    margin: 7.5px;
}

.instructorProfilePicture {
    border-radius: 100px;
    width: 60px;
}

.section2_col_data_container {
    margin-left: 5px;
    margin-right: 5px;
}

.section2_col_data_container>* {
    margin: 5px;
    white-space: nowrap;
}

.section2_col_icon {
    color: #aaaaaa;
    /* color: #00DAB2; */
}

.section2_col_data {
    color: #aaaaaa;
    /* color: #00DAB2; */
    font-weight: 300;
    font-size: 14px;
}

.listItem {
    font-size: 16px;
}

.spinner {
    font-size: 50px;
    animation: spinner 1s infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
