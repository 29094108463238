@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
  --swiper-pagination-color: #00dab2;
}

a {
  color: #545454;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

a:hover {
  color: #00DAB2;
}

h1 {
  color: #0B2B44;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 60px;
  text-transform: capitalize;
  line-height: 60px;
}

h2 {
  color: #0B2B44;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 40px;
  text-transform: capitalize;
  line-height: 60px;
}

h3 {
  color: #00DAB2;
  font-family: "Poppins", Sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  line-height: 24px;
}

p {
  color: #545454;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.btn {
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 17px;
  padding: 9px 33px;
  width: fit-content;
  border-width: 3px;
  border-radius: 5px;
  cursor: pointer;
  margin: 7.5px;

  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #545454;
  --bs-btn-disabled-border-color: #545454;
  --bs-btn-disabled-opacity: 75%;
}

.btn-primary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #0B2B44;
  --bs-btn-border-color: #0B2B44;
  --bs-btn-hover-color: #0B2B44;
  --bs-btn-hover-bg: #FFFFFF;
  --bs-btn-hover-border-color: #0B2B44;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #0B2B44;
  --bs-btn-active-border-color: #0B2B44;
}

.btn-secondary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #00DAB2;
  --bs-btn-border-color: #00DAB2;
  --bs-btn-hover-color: #00DAB2;
  --bs-btn-hover-bg: #FFFFFF;
  --bs-btn-hover-border-color: #00DAB2;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #00DAB2;
  --bs-btn-active-border-color: #00DAB2;
}

.container {
  padding-top: 70px;
  padding-bottom: 70px;
}

.nav-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-logo {
  width: 200px;
}

.navbar {
  position: relative;
  margin-bottom: -120px;
}

.nav-item {
  color: #FFFFFF;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
}

.nav-item::after {
  content: none;
}

.nav-item-angle {
  padding-left: 7.5px;
}

.nav-item-dark {
  color: #0B2B44;
}

.nav-item:hover {
  color: #FFFFFF;
  font-weight: 600;
}

.nav-item:focus {
  color: #FFFFFF;
  font-weight: 600;
}

.nav-item .active {
  color: #FFFFFF;
  font-weight: 600;
}

.nav-item-dark:hover {
  color: #0B2B44;
  font-weight: 600;
}

.nav-item-dark:focus {
  color: #0B2B44;
  font-weight: 600;
}

.nav-item-dark .active {
  color: #0B2B44;
  font-weight: 600;
}

.dropdown {
  padding: 15px 15px 0px 15px;
}

.dropdown-menu {
  --bs-dropdown-spacer: 0px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 1px;
  border-width: 0px;
}

.dropdown-spacer {
  height: 5px;
}

.dropdown-item {
  color: #0B2B44;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  text-transform: capitalize;
}

.account-dropdown-item {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}

.col {
  padding: 15px;
}

.footer-logo {
  max-width: 60%;
  margin-bottom: 16px;
}

.footer-contact {
  color: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
}

.footer-contact-icon {
  margin-right: 15px;
  color: #FFFFFF;
}

.footer-social {
  border-radius: 100px;
  width: 35px;
  height: 35px;
}

.footer-social-icon {
  width: 17.5px;
  height: 17.5px;
  color: #FFFFFF;
}

.section4-img {
  margin-bottom: 16px;
}

.footer-contact:hover {
  color: #545454;
}

.header {
  background: url(./assets/images/waves.svg) no-repeat bottom left, linear-gradient(90deg, #6740E2, #50B7ED);
}

.header-title {
  color: #FFFFFF;
}

.header-container {
  padding-top: 150px;
  padding-bottom: 150px;
}

li {
  color: #545454;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.footer-waves {
  width: 100%;
}

.footer {
  background: linear-gradient(90deg, #6740E2, #50B7ED);
}

.footer-title {
  color: #FFFFFF !important;
}

.footer-text {
  color: #FFFFFF !important;
}

.footer-text:hover {
  color: #FFFFFF !important;
}

.footer-hr {
  color: #FFFFFF;
  height: 3px;
}

.facebook-colors {
  background: #4f6aa3;
}

.instagram-colors {
  background: linear-gradient(135deg, #833AB4, #F56040 50%, #FCAF45);
}

.linkedin-colors {
  background: #0077B5;
}

.youtube-colors {
  background: #FF0000;
}

.carousel-inner {
  margin: 15px;
}

.carousel-control-next, .carousel-control-prev {
  width: 60px;
}

.form {
  padding: 25px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 6px 6px 38px #eeeefc;
  border-width: 1px;
  border-color: #ffffff;
}

.form-group {
  margin: 7.5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-control {
  border-radius: 5px;
  border-width: 1px;
  border-color: #0B2B44;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none;
}

.form-control-inner {
  border-radius: 5px;
  border: none;
  outline: none;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #545454;
  width: 100%;
}

.form-control.error {
  color: #FF0000;
  border-color: #FF0000;
}

.form-control-message {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #545454;
  margin-bottom: 7.5px;
}

.form-control-message.error {
  color: #FF0000;
}

.form-checkbox {
  margin: 0px;
}

.form-control-checkbox {
  border: none;
}

.form-control-checkbox-inner {
  width: auto;
  margin-right: 24px;
}

.form-control-checkbox-label {
  font-size: 16px;
  margin: 0px;
  line-height: 24px;
}

.nav-pills {
  --bs-nav-pills-border-radius: 5px;
  --bs-nav-pills-link-active-color: #FFFFFF;
  --bs-nav-pills-link-active-bg: #0B2B44;
}

.nav-link {
  --bs-nav-tabs-link-active-color: #0B2B44;
  --bs-nav-link-color: #0B2B44;
  --bs-nav-link-hover-color: #0B2B44;
}

.navbar-account-dropdown::after {
  content: none !important;
}

.navbar-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.accordion-button {
  padding: 7.5px;
  height: 65px;
}

.partners {
  padding: 0px;
}

.partners > .carousel-inner {
  margin: 0px;
}

.partners > .carousel-indicators {
  display: none;
}

.partners > .carousel-inner > .carousel-item {
  height: 700px;
}

.contact-us {
  width: 60%;
}
