.section1 {
  background: url("../../assets/images/bg-1.png") top right / contain no-repeat;
  padding-top: 120px;
}

.section1_btn_col {
  padding: 0px;
}

.section1_btn {
  margin-top: 7.5px;
  margin-right: 15px;
  margin-bottom: 7.5px;
}

.section1_img {
  max-width: 75%;
}


.section2 {
  background: url("../../assets/images/bg-2.png") no-repeat top left;
}

.section2_col {
  padding: 25px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 6px 6px 38px #eeeefc;
  border-width: 1px;
  border-color: #ffffff;
  margin: 15px;
  max-width: 350px;
}

.section2_col_icon {
  width: 150px;
  height: 150px;
}

.section2_col_title, .section4_slider_title {
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.section2_col_p {
  font-weight: 300;
  font-size: 16px;
}

.section2_col_btn {
  margin-top: 23px;
}


.section3_col {
  padding: 12.5px 0px;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid #00DAB2;
  max-width: 325px;
}

.section3_col_icon {
  width: 115px;
  height: 115px;
  padding: 10px;
}

.section3_col_counter {
  padding: 10px;
}

.section4 {
  background: url("../../assets/images/bg-3.png") no-repeat top right;
}

.section4_slider {
  box-shadow: 8px 8px 38px #eeeefc;
  padding: 50px 40px;
  max-width: 750px;
  min-height: 400px;
  background-color: #FFFFFF;
}

.section4_slider_next {
  color: #00DAB2;
  font-size: 30px;
}

.section4_slider_prev {
  color: #00DAB2;
  font-size: 30px;
}


.section5_col {
  padding: 15px;
  max-width: 545px;
}

.section5_col_img {
  width: 300px;
  height: 300px;
}

.section5_col_title {
  font-size: 38px;
}


.section6_col {
  padding: 7.5px;
}

.col_icon {
  color: #aaaaaa;
}

.section2_col_data {
  color: #aaaaaa;
  font-weight: 300;
  font-size: 14px;
}

.row_col {
  flex-wrap: nowrap;
}

.section2_col_data_container {
  margin-left: 5px;
  margin-right: 5px;
}

.section2_col_data_container>* {
  margin: 5px;
}

.spinner {
  font-size: 50px;
  animation: spinner 1s infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}