.galleryImage {
    transform: scale(1);
    transition: 0.5s;
}

.galleryThumbnailContainer {
    display: flex;
    position: relative;
}

.galleryVideo {
    transform: scale(1);
    transition: 0.5s;
}

.galleryIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
}

.galleryCol {
    height: 300px;
}

.galleryImage:hover, .galleryVideo:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.galleryModal {
    width: 100%;
    height: 100%;
    background-color: #00000090;
}

.galleryModal > div > div {
    background: none;
    border: none;
}

.galleryModalBody {
    padding: 0px;
}

.galleryModalImage, .galleryModalVideo {
    width: 100%;
    height: calc(100vh - 100px);
}
